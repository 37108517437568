import {
  Combobox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from '@bb-ui/react-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LearnConfigDetails } from 'App.types';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ChevronRight } from '@bb-ui/icons/dist/small';
import { useConfigurationContext } from 'contexts/ConfigurationContext';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import ActionsUpdateConfigurationDialog from 'dialogs/ActionsUpdateConfigurationDialog';
import { IJobConfig } from '@lct-jobs/core';
import { useTenantContext } from 'contexts/TenantContext';

export interface SiteConfigurationProps {
  schema: object;
}

export const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      marginBottom: '14px',
    },
    section: {
      marginBottom: '45px',
    },
    combobox: {
      display: 'flex',
      flexWrap: 'wrap',
      gridGap: '20px',
      flexDirection: 'column',
      float: 'right',
      marginBottom: '20px',
    },
  }),
);

export const SiteConfiguration: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedDomain, setSelectedDomain] = React.useState<string>('');
  const [newDomain, setNewDomain] = React.useState<boolean>(false);
  const [configValues, setConfigValues] = React.useState<Record<string, any>>({});
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [updatedSchema, setUpdatedSchema] = React.useState<any>({});
  const [loadingAutofill, setLoadingAutofill] = React.useState<boolean>(true);
  const [shouldUseUpdatedSchema, setShouldUseUpdatedSchema] = React.useState(false);

  const { domains, configuration, configLoading, configErrors } = useConfigurationContext();
  const { tenantId } = useTenantContext();
  const {
    fetch,
    data: singularDomainConfig,
    loading: domainLoading,
    error: domainError,
  } = useRestApi(apiUrl('lct', `tenants/${tenantId}/domains/${selectedDomain}/schemaDetail`), {
    manual: true,
  });

  const configItems = configuration?.items ?? [];
  const siteConfig = React.useMemo<LearnConfigDetails[]>(() => configItems ?? [], [configItems]);
  const activeDomains = siteConfig.map((item: any) => item.domain) ?? [];
  const allDomainItems = domains?.items ?? [];
  const filteredDomainItems = allDomainItems.filter(
    (domainItem: string) => !activeDomains.includes(domainItem),
  );

  const domainConfig = React.useMemo(
    () => filteredDomainItems.map((item: string) => ({ label: item, value: item })),
    [filteredDomainItems],
  );

  const uiSchema = singularDomainConfig?.schema?.uiSchema ?? {};
  const schema = singularDomainConfig?.schema?.schema ?? {};

  const uiFormSchema = React.useMemo<IJobConfig[]>(() => uiSchema ?? {}, [uiSchema]);
  const formSchema = React.useMemo<IJobConfig[]>(() => schema ?? {}, [schema]);
  const formData = React.useMemo<Record<string, any>>(() => configValues ?? {}, [configValues]);

  const strings = {
    announceOptionSelected: (option: any) => `${option.label}, selected.`,
    announceOptionDeselected: (option: any) => `${option.label}, deselected.`,
    announceValueCleared: 'All values deselected.',
    announceSearchResults: (count: number, searchString: string) => {
      switch (count) {
        case 0:
          return `No results found for "${searchString}"`;
        case 1:
          return `${count} result found for "${searchString}". Press down arrow to navigate through the results.`;
        default:
          return `${count} results found for "${searchString}". Press down arrow to navigate through the results.`;
      }
    },
    noResults: (searchString: string) => `No results found for "${searchString}"`,
    clearButtonLabel: 'Deselect all values.',
    searchLabel: 'Search',
  };

  React.useEffect(() => {
    if (selectedDomain) {
      fetch();
    }
  }, [selectedDomain, fetch]);

  React.useEffect(() => {
    if (singularDomainConfig && Object.keys(singularDomainConfig.resolvedAutoFillKeys).length > 0) {
      setLoadingAutofill(false);
      const schemaWithProperties = schema as { properties: { [key: string]: any } };
      const newSchema = {
        ...schemaWithProperties,
        properties: { ...schemaWithProperties.properties },
      };

      Object.keys(singularDomainConfig.resolvedAutoFillKeys).forEach((key) => {
        const resolvedKey = singularDomainConfig.resolvedAutoFillKeys[key];
        Object.keys(newSchema.properties).forEach((propKey) => {
          const property = newSchema.properties[propKey];
          if (property.autofillKey === key) {
            if (resolvedKey.defaultValue) property.default = resolvedKey.defaultValue;
            if (resolvedKey.examples) property.examples = resolvedKey.examples;
            if (resolvedKey.enum) property.enum = Object.values(resolvedKey.enum);
          }
        });
      });

      setUpdatedSchema(newSchema);
      setShouldUseUpdatedSchema(true);
    } else {
      setShouldUseUpdatedSchema(false);
    }
  }, [singularDomainConfig, schema]);

  let siteConfiguration = <LoadingIndicator data-testid="loading-config" />;
  if (configErrors) {
    siteConfiguration = <Typography data-testid="site-error">{t('global.fetchError')}</Typography>;
  } else if (!configLoading) {
    siteConfiguration = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell role="columnheader">{t('siteConfiguration.domain')}</TableCell>
            <TableCell role="columnheader" />
          </TableRow>
        </TableHead>
        <TableBody>
          {siteConfig.map((item) => (
            <TableRow key={item.domain}>
              <TableCell
                aria-colindex={1}
                tabIndex={-1}
                aria-describedby="config-list-table-domain"
              >
                {item.domain}
              </TableCell>
              <TableCell
                aria-colindex={2}
                tabIndex={-1}
                aria-describedby="site-list-table-header-actions"
                align="right"
              >
                <IconButton
                  id={`update-config-button-${item.domain}`}
                  data-testid="update-config-button"
                  aria-label="Actions for configuration"
                  onClick={() => {
                    setSelectedDomain(item.domain);
                    setConfigValues(item.configuration);
                    setDialogIsOpen(true);
                  }}
                >
                  <Typography component="span" variant="inherit">
                    {t('global.view')}
                  </Typography>
                  <ChevronRight />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <div data-testid="learn-site-configuration-page">
      <div className={classes.combobox}>
        <Combobox
          floatingLabel
          id="combobox-single"
          label={t('siteConfiguration.selectDomain')}
          placeholder="Choose One"
          strings={strings}
          options={domainConfig}
          onChange={(value, data) => {
            setSelectedDomain(data.option!.value);
            setNewDomain(true);
            setDialogIsOpen(true);
          }}
        />
      </div>
      <div className={classes.section}>{siteConfiguration}</div>
      <ActionsUpdateConfigurationDialog
        onClose={() => setDialogIsOpen(false)}
        open={dialogIsOpen}
        id={selectedDomain}
        selectedDomain={selectedDomain}
        loading={domainLoading}
        error={!!domainError}
        uiSchema={uiFormSchema}
        schema={shouldUseUpdatedSchema && newDomain ? updatedSchema : formSchema}
        formData={shouldUseUpdatedSchema && newDomain ? {} : formData}
        newDomain={newDomain}
        loadingAutofill={loadingAutofill}
      />
    </div>
  );
};
